import React from 'react';
import { ResponsivePie } from '@nivo/pie';
import { useTheme } from '@mui/material';
import { tokens } from '../theme';

import { useState,useEffect } from 'react';
import { db } from '../firebase'; // Import your Firebase database instance
import { collection, getDocs } from 'firebase/firestore';
const PieChart = ({isDashboard=false}) => {
    const [pieData, setPieData] = useState([]);
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

    useEffect(() => {
        const fetchTransactionData = async () => {
            try {
                const currentUserPhone = localStorage.getItem('phoneNumber');
                const transactionsRef = collection(db, 'users', currentUserPhone, 'transactions');
                const querySnapshot = await getDocs(transactionsRef);
                const data = [];

                querySnapshot.forEach((doc) => {
                    const transactionData = doc.data();
                    if (transactionData.Sender.phone === currentUserPhone) {
                        data.push({
                            id: transactionData.Receiver.phone, // Use receiver phone as ID
                            label: transactionData.Receiver.name, // Use receiver name as label
                            value: transactionData.Amount, // Use transaction amount as value
                            color: colors.primary[400], // Use a fixed color or generate dynamically
                        });
                    }
                });

                setPieData(data);
            } catch (error) {
                console.error('Error fetching transaction data:', error);
            }
        };

        fetchTransactionData();
    }, );

    return (
        <ResponsivePie
            theme={{
                axis:{
                    domain: {           
                        line:{
                            stroke:colors.grey[100]
                        }
                    },
                    legend:{
                        text:{
                        fill:colors.grey[100] 
                        }
                    },
                    ticks:{
                        line:{
                        stroke:colors.grey[100],
                        strokeWidth:1
                        },
                        text:{
                            fill:colors.grey[100] 
                        }
                    }
                },
                legends:{
                    text:{
                        fill:colors.grey[100]
                    }
                },
                tooltip: {
                    container: {
                        background: colors.primary[400],
                        color: colors.grey[100],
                    },
                }
            }}
            data={pieData}
            margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
            innerRadius={0.5}
            padAngle={0.7}
            cornerRadius={3}
            activeOuterRadiusOffset={8}
            borderWidth={1}
            borderColor={{
                from: 'color',
                modifiers: [
                    [
                        'darker',
                        0.2
                    ]
                ]
            }}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsTextColor={colors.grey[100]}
            arcLinkLabelsThickness={2}
            arcLinkLabelsColor={{ from: 'color' }}
            arcLabelsSkipAngle={10}
            arcLabelsTextColor={{
                from: 'color',
                modifiers: [
                    [
                        'darker',
                        2
                    ]
                ]
            }}
            defs={[
                {
                    id: 'dots',
                    type: 'patternDots',
                    background: 'inherit',
                    color: 'rgba(255, 255, 255, 0.3)',
                    size: 4,
                    padding: 1,
                    stagger: true
                },
                {
                    id: 'lines',
                    type: 'patternLines',
                    background: 'inherit',
                    color: 'rgba(255, 255, 255, 0.3)',
                    rotation: -45,
                    lineWidth: 6,
                    spacing: 10
                }
            ]}

            legends={[
                {
                    anchor: 'bottom',
                    direction: 'row',
                    justify: false,
                    translateX: 0,
                    translateY: 56,
                    itemsSpacing: 0,
                    itemWidth: 100,
                    itemHeight: 18,
                    itemTextColor: colors.grey[100],
                    itemDirection: 'left-to-right',
                    itemOpacity: 1,
                    symbolSize: 18,
                    symbolShape: 'circle',
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemTextColor: colors.greenAccent[400],
                            }
                        }
                    ]
                }
            ]}
        />
    )

}

export default PieChart